<template>
  <div v-if="$store.getters.isAuth">
    <CardUI v-if="!$store.state.user.currentUser.phone_confirmed">
      <template v-slot:header>
        Для продолжения работы, подтвердите номер телефона
      </template>
      <template v-slot:body>
        <h4 class="my-4">Ваш номер телефона</h4>
        <InputUI v-model="phone" v-mask="'+7##########'" autofocus
                 placeholder="Номер телефона"></InputUI>
        <ButtonUI :disabled="wait > 0 || loading" v-if="!token" :loading="loading" type="default" @click="requestCode">
          Отправить код
        </ButtonUI>
        <template v-if="token">
          <h4 class="my-4">Код подтверждения</h4>
          <p>Сейчас на ваш телефон позвонит робот, введите последние 4 цифры номера телефона:</p>
          <InputUI :errors="codeErrors" v-model="code" v-mask="'####'" placeholder="1234"></InputUI>
          <ButtonUI @click.native="confirmCode" class="mb-4" type="default">Подтвердить</ButtonUI>
          <ButtonUI @click="requestCode" type="secondary">Отправить код еще раз</ButtonUI>
        </template>

        <div style="color: #999999" v-if="wait > 0" class="text-center mt-4">
          Вы уже запрашивали код подтверждения, ожидайте {{ wait }} секунд
        </div>
      </template>
    </CardUI>
    <CardUI v-else>
      <template v-slot:header>
        Подтверждение телефона
      </template>
      <template v-slot:body>
        <div class="text-center">
          Ваш номер <b>{{ $store.state.user.currentUser.phone }}</b> успешно подтвержден
          <ButtonUI @click="$store.state.user.currentUser.phone_confirmed = false" class="mt-4">Сменить номер</ButtonUI>
        </div>
      </template>
    </CardUI>
  </div>
</template>

<script>
import CardUI from "@/components/UI/CardUI.vue";
import InputUI from "@/components/UI/InputUI.vue";
import ButtonUI from "@/components/UI/ButtonUI.vue";
import {mask} from "vue-the-mask";
import user from "@/api/user.js";

export default {
  name: "ConfirmationPhoneComponent",
  directives: {
    mask,
  },
  data() {
    return {
      token: null,
      code: null,
      loading: false,
      phone: this.$store.state.user.currentUser.phone,
      wait: false,
      waitInterval: null,
      codeErrors: []
    }
  },
  methods: {
    confirmCode() {
      this.loading = true;
      user.confirmCode(this.code, this.token).then((r) => {
        // eslint-disable-next-line no-empty
        if (r.data.status === 'success') {
          this.$store.dispatch('getUser');
          this.$emit('success');
        } else {
          this.codeErrors = ['Неверный код подтверждения']
        }
      });

    },
    requestCode() {
      this.loading = true;
      user.requestCode(this.phone).then((r) => {
        this.loading = false;
        if (r.data.token === false) {
          this.wait = 30;
          this.waitInterval = setInterval(() => {
            if (this.wait >= 1) {
              this.wait -= 1;
            } else {
              clearInterval(this.waitInterval)
            }
          }, 1000);
        } else {
          this.token = r.data.token;
        }
      })
    }
  },
  mounted() {
    this.phone = this.$store.state.user.currentUser.phone;
  },
  components: {CardUI, InputUI, ButtonUI}
}
</script>

<style scoped>

</style>
